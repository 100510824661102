import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "concepts"
    }}>{`Concepts`}</h1>
    <h2 {...{
      "id": "seating-offer"
    }}>{`Seating offer`}</h2>
    <p>{`A seating offer is an offering of seats (or other reservable entities) that can be reserved on a departure. A departure is served by one or more carriages/vehicles, and each carriage has a number of seats that can be reserved. Seating offers visit a set of stop-places as part of the departure, and the composition of carriages might change underway.`}</p>
    <h3 {...{
      "id": "material-composition"
    }}>{`Material composition`}</h3>
    <p>{`A material composition describes the set of carriages that service a departure, including their direction and order. Most departures have a single material composition, but some departures have multiple, where each composition service only part of the stop-places.
For instance, the Sørtoget train from Oslo to Stavanger is always composed of 2 material compositions because Kristiansand is a terminus station, and trains has to continue in the reverse direction towards Stavanger. One material composition will service Oslo - Kristiansand, and the other Kristiansand - Stavanger with the same carriages traveling in the opposite direction.
It is also possible to have different materials, one might for instance connect/disconnect carriages at specific stop-places if necessary.`}</p>
    <h2 {...{
      "id": "seat-reservation"
    }}>{`Seat reservation`}</h2>
    <p>{`A seat reservation is a reservation of an entity that can be occupied by a passenger. This can be a seat, a bed, a bicycle space, a wheelchair space etc, but for simplicity we will refer to this entity as a seat. Seats are reserved on a specific departure from an origin stop-place where the passenger(s) boards until the destination stop-place where the passenger(s) alights.`}</p>
    <p>{`A reservation consist of a set of reservation lines. There are mainly 2 types of reservation lines:`}</p>
    <ul>
      <li parentName="ul">{`SingleSeatReservationLine: reserves a single seat`}</li>
      <li parentName="ul">{`CompartmentReservationLine: reserves a compartment of potentially multiple seats`}</li>
    </ul>
    <p>{`Reservations have a status, which can be DRAFT, CONFIRMED, CANCELLED or DEPRECATED. A reservation can be created with the status DRAFT, and then be confirmed (usually when receiving payment) or cancelled. A reservation can also be deprecated if the seating offer has changed, and the reservation is no longer valid. If changes are made to an CONFIRMED reservation, a new version is created with the status DRAFT, and until confirmation both versions will still be active. An example of this can be `}<a parentName="p" {...{
        "href": "/pages-seating-guides#altering-a-seat-reservation"
      }}>{`found in the guides`}</a>{`.`}</p>
    <h2 {...{
      "id": "seating-properties"
    }}>{`Seating properties`}</h2>
    <p>{`A central property of a seat is its seatingProperties. They describe the type of seat, and various other properties that are relevant for the passenger. For instance, many trains have premium compartments with extra space, and these seats are often more expensive. The seatingProperties can also describe if the seat is a window seat, if it has a table, if it is located in a quiet zone or in a family area etc.`}</p>
    <p>{`Seat reservations are based on seating properties, they can be created with the `}<a parentName="p" {...{
        "href": "/pages-seating-guides#create-a-draft-reservation-and-reserve-seat-and-compartment-by-seating-properties"
      }}>{`/allocate`}</a>{` endpoint. Entur Plass will then pick the best seats available in proximity to each other, while respecting the seating properties that the seat needs to have. If there is not enough available seats to accommodate all requested seatingProperties, the reservation will fail.`}</p>
    <p>{`One might also create/update a reservation by reserving specific seats, without specifying seatingProperties. The updateReservationLines-endpoint can be used for this (LINK til guide). This can be used in combination with a graphical seat map, where the passenger can select the seats they want to reserve.`}</p>
    <h2 {...{
      "id": "rebooking"
    }}>{`Rebooking`}</h2>
    <p>{`Rebooking is the process of adapting a seat reservation after something has happened to the seat or seating offer. `}<a parentName="p" {...{
        "href": "/pages-seating-rebooking"
      }}>{`See separate page for more details`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      